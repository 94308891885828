exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admission-document-js": () => import("./../../../src/pages/admission-document.js" /* webpackChunkName: "component---src-pages-admission-document-js" */),
  "component---src-pages-constitutional-documents-js": () => import("./../../../src/pages/constitutional-documents.js" /* webpackChunkName: "component---src-pages-constitutional-documents-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modern-day-slavery-js": () => import("./../../../src/pages/modern-day-slavery.js" /* webpackChunkName: "component---src-pages-modern-day-slavery-js" */),
  "component---src-pages-regulatory-announcements-js": () => import("./../../../src/pages/regulatory-announcements.js" /* webpackChunkName: "component---src-pages-regulatory-announcements-js" */),
  "component---src-pages-reports-and-accounts-js": () => import("./../../../src/pages/reports-and-accounts.js" /* webpackChunkName: "component---src-pages-reports-and-accounts-js" */),
  "component---src-pages-shareholder-documents-js": () => import("./../../../src/pages/shareholder-documents.js" /* webpackChunkName: "component---src-pages-shareholder-documents-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

